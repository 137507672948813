// @flow
import { fork, put, select, take } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { handleActions } from "redux-actions";
import { push } from "connected-react-router";

import { APP_NAMESPACE } from "shared/constants/application";
import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import {
  NONE,
  LOADING,
  LOADED,
  SUCCESS,
  ERROR,
  PENDING,
} from "shared/constants/status";
import { EDIT } from "redux/modules/createCourse/constants.js";
import { VOID } from "shared/constants/form";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import type { Course } from "redux/api-types/Course";
import {
  getRequestCurriculaStatus,
  getSetCurricula as getCurricula,
  getSetCurriculaModules as getCurriculaModules,
  getRequestRangesStatus,
  getRanges,
} from "../createCourse/createCourse";
import {
  CURRICULA_REQUEST_SUCCESS,
  CURRICULA_REQUEST_ERROR,
  RANGES_REQUEST_SUCCESS,
  RANGES_REQUEST_ERROR,
} from "../createCourse/createCourseActions";
import { getJwtUser } from "shared/utilities/authCookie";
import { parseInitialCourseFormValues } from "./courseParser";
import { parseFormValues } from "../createCourse/parsers/form";
import { states } from "shared/constants/states";
import { getOptionFromSelectedValue } from "../createCourse/parsers/selects";
import { setSpecialSelect } from "../createCourse/createCourseActionCreators";
import type {
  CourseSubmission,
  CourseReponse,
} from "../createCourse/createCourseActionCreators";
import type { Include as IncludeType } from "Redux/api-types/Include";

// actions
const namespace: string = `${APP_NAMESPACE}/editCourse`;

export const COURSE_REQUEST: string = `${namespace}/REQUEST/FETCH`;
export const COURSE_REQUEST_SUCCESS: string = `${namespace}/REQUEST/SUCCESS`;
export const COURSE_REQUEST_ERROR: string = `${namespace}/REQUEST/ERROR`;
export const UPDATE_COURSE_REQUEST: string = `${namespace}/UPDATE/REQUEST`;
export const UPDATE_COURSE_SUCCESS: string = `${namespace}/UPDATE/SUCCESS`;
export const UPDATE_COURSE_ERROR: string = `${namespace}/UPDATE/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// Flow Types
export type ReceiveCourseResponse = {
  data: Course,
  included: Array<IncludeType>,
};

export type ReceiveCourseReturn = {
  type: string,
  payload: {
    course: ReceiveCourseResponse,
  },
  meta: {
    receivedAt: number,
  },
};

export type RequestEditCourseReturn = {
  type: string,
  payload: {
    course: CourseSubmission,
  },
};

export type UpdateCourseSuccessReturn = {
  type: string,
  payload: {
    course: ReceiveCourseResponse,
  },
  meta: {
    receivedAt: number,
  },
};

export type InitialFormValues = {
  name?: string,
  overrideUrl?: string,
  isSearchable: boolean,
  curriculumId?: string,
  curriculumModuleId?: string,
  price?: number,
  capacity?: number,
  description?: string,
  notes?: string,
  classroomHours?: number,
  rangeHours?: number,
  hasLiveFire?: boolean,
  grantsCCW?: number,
  environment?: string,
  isWheelchairAccessible?: boolean,
  isWomensOnly?: boolean,
  location?: string,
  address1?: string,
  address2?: string,
  city?: string,
  state?: string,
  postalCode?: string,
  registrationUrl?: string,
  registrationEmail?: string,
  registrationPhone?: string,
  [string]: boolean | string,
  searchableProhibited?: boolean,
  searchableOptional?: boolean,
  searchableRequired?: boolean,
  registrationType: string,
};

// action creators
export const requestCourse = (): simpleReduxAction => ({
  type: COURSE_REQUEST,
});

export const receiveCourse = (
  json: ReceiveCourseResponse
): ReceiveCourseReturn => ({
  type: COURSE_REQUEST_SUCCESS,
  payload: {
    course: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const courseRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: COURSE_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const requestUpdateCourse = (
  course: CourseSubmission
): RequestEditCourseReturn => ({
  type: UPDATE_COURSE_REQUEST,
  payload: {
    course,
  },
});

export const updateCourseSuccess = (
  json: CourseReponse
): UpdateCourseSuccessReturn => ({
  type: UPDATE_COURSE_SUCCESS,
  payload: {
    course: json,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const updateCourseError = (error: apiErrorParams): apiErrorAction => ({
  type: UPDATE_COURSE_ERROR,
  error: true,
  payload: {
    errorMessage: undefined,
    errors: [],
    ...error,
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
const { userId } = getJwtUser();

export const getCourse = (id: string): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: `/api/training/courses/${id}?include=images`,
    actions: {
      request: requestCourse,
      success: receiveCourse,
      failure: courseRequestError,
    },
  },
});

export const updateCourse = (
  id: string,
  course: CourseSubmission
): callApiReturnType => {
  const options = {
    ...course,
    instructorId: userId,
    isPublished: true,
  };

  const data = parseFormValues(options);

  return {
    type: CALL_API,
    payload: {
      method: "PATCH",
      endpoint: `/api/training/courses/${id}`,
      options: data,
      actions: {
        request: requestUpdateCourse,
        success: updateCourseSuccess,
        failure: updateCourseError,
      },
      toasts: {
        success: "Course Updated",
        failure: "Couldn't Update Course",
      },
    },
  };
};

// State
export type State = {
  hasOccurrences: boolean,
  id: string,
  requestCourseStatus: string,
  requestCourseErrorMessage: string,
  requestCourseErrors: apiErrorReturn,
  initialFormValues: InitialFormValues,
  updateCourseStatus: string,
  updateCourseErrorMessage: string,
  updateCourseErrors: ?apiErrorReturn,
  registrationType: string,
};

const initialState = {
  hasOccurrences: false,
  id: "",
  requestCourseStatus: NONE,
  requestCourseErrorMessage: "",
  requestCourseErrors: [],
  initialFormValues: {
    isSearchable: true,
    registrationType: "uscca",
  },
  updateCourseStatus: NONE,
  updateCourseErrorMessage: "",
  updateCourseErrors: [],
};

// Selectors
export const getRequestCourseStatus = ({
  editCourse: { requestCourseStatus },
}: {
  editCourse: State,
}): boolean => requestCourseStatus;

export const getState = (state: { editCourse: State }): ?string => {
  const { initialFormValues } = state.editCourse;
  const selectedState = initialFormValues ? initialFormValues.state : null;

  return selectedState;
};

export const getCurriculumId = (state: { editCourse: State }): ?string => {
  const { initialFormValues } = state.editCourse;
  const curriculumId = initialFormValues
    ? initialFormValues.curriculumId
    : null;
  return curriculumId;
};

export const getCurriculumModuleId = (state: {
  editCourse: State,
}): ?string => {
  const { initialFormValues } = state.editCourse;
  const curriculumModuleId = initialFormValues
    ? initialFormValues.curriculumModuleId
    : null;

  return curriculumModuleId;
};

export const getOrganizationId = (state: { editCourse: State }): ?string => {
  const { initialFormValues } = state.editCourse;
  const organizationId = initialFormValues
    ? initialFormValues.organizationId
    : null;
  return organizationId;
};

export const getMode = ({
  createCourse: { mode },
}: {
  createCourse: State,
}): string => mode;

// Sagas
// could be optimized, some put actions can run multiple times
function* checkSelects(): Saga<void> {
  const requestCourseStatus = yield select(getRequestCourseStatus);
  const requestCurriculaStatus = yield select(getRequestCurriculaStatus);
  const requestRangesStatus = yield select(getRequestRangesStatus);
  const mode = yield select(getMode);

  if (!requestCourseStatus !== LOADING && !requestCurriculaStatus !== LOADING) {
    // set curricula
    const curriculumId = yield select(getCurriculumId);
    const curriculum = yield select(getCurricula);
    if (curriculumId && curriculum.hasOwnProperty(curriculumId)) {
      if (curriculumId) {
        const curriculaOption = {
          value: curriculumId,
          label: curriculum[curriculumId].name,
        };

        const setSpecialSelectAction = setSpecialSelect(
          "curriculumId",
          curriculaOption
        );
        yield put(setSpecialSelectAction);
      }
    }

    if (mode === EDIT && !curriculumId) {
      const curriculaOption = {
        value: VOID,
        label: "Non-uscca Curriculum",
      };

      const setSpecialSelectAction = setSpecialSelect(
        "curriculumId",
        curriculaOption
      );
      yield put(setSpecialSelectAction);
    }

    // set curricula modules
    const curriculumModuleId = yield select(getCurriculumModuleId);
    const curriculaModules = yield select(getCurriculaModules);
    if (
      curriculumModuleId &&
      curriculaModules.hasOwnProperty(curriculumModuleId)
    ) {
      const curriculumModuleOption = {
        value: curriculumModuleId,
        label: curriculaModules[curriculumModuleId].name,
      };

      const setSpecialSelectAction = setSpecialSelect(
        "curriculumModuleId",
        curriculumModuleOption
      );
      yield put(setSpecialSelectAction);
    }
  }

  // set organization in special select dropdown
  if (!requestCourseStatus !== LOADING && !requestRangesStatus !== LOADING) {
    const organizationId = yield select(getOrganizationId);
    const ranges = yield select(getRanges);
    if (organizationId && ranges.hasOwnProperty(organizationId)) {
      const rangeOption = {
        value: organizationId,
        label: ranges[organizationId].name,
      };
      yield put(setSpecialSelect("organizationId", rangeOption));
    }
  }
}

function* watchCurriculumSuccess(): Saga<void> {
  for (;;) {
    yield take(CURRICULA_REQUEST_SUCCESS);
    yield fork(checkSelects);
  }
}

function* watchCurriculumFail(): Saga<void> {
  for (;;) {
    yield take(CURRICULA_REQUEST_ERROR);
    yield fork(checkSelects);
  }
}

function* watchRangesSuccess(): Saga<void> {
  for (;;) {
    yield take(RANGES_REQUEST_SUCCESS);
    yield fork(checkSelects);
  }
}

function* watchRangesFail(): Saga<void> {
  for (;;) {
    yield take(RANGES_REQUEST_ERROR);
    yield fork(checkSelects);
  }
}

function* watchCourseSuccess(): Saga<void> {
  for (;;) {
    yield take(COURSE_REQUEST_SUCCESS);
    yield fork(checkSelects);
    // set state selection
    const state = yield select(getState);
    const stateValue = getOptionFromSelectedValue(state, states);
    const setSpecialSelectAction = setSpecialSelect("state", stateValue);
    yield put(setSpecialSelectAction);
  }
}

export function* initilizeSelects(): Saga<void> {
  yield fork(watchCurriculumSuccess);
  yield fork(watchCurriculumFail);
  yield fork(watchRangesSuccess);
  yield fork(watchRangesFail);
  yield fork(watchCourseSuccess);
}

export function* onUpdateCourse(): Saga<void> {
  for (;;) {
    yield take(UPDATE_COURSE_SUCCESS);
    const url = "/my-courses";
    yield put(push(url));
  }
}

const editCourse = handleActions(
  {
    [COURSE_REQUEST]: (state: State, action: simpleReduxAction): State => ({
      ...state,
      requestCourseStatus: LOADING,
      requestCourseErrorMessage: "",
      requestCourseErrors: [],
    }),
    [COURSE_REQUEST_SUCCESS]: (
      state: State,
      action: ReceiveCourseReturn
    ): State => {
      const { data, included } = action.payload.course;
      let imageId = "";
      let imageUrl = "";
      if (included && included[0]) {
        imageId = included[0].id ? included[0].id : "";
        imageUrl = included[0].attributes.url ? included[0].attributes.url : "";
      }
      const {
        id,
        attributes: { pastOccurrencesCount, futureOccurrencesCount },
      } = data;
      const initialFormValues = parseInitialCourseFormValues(
        data,
        imageId,
        imageUrl
      );
      return {
        ...state,
        id,
        hasOccurrences: !!(pastOccurrencesCount || futureOccurrencesCount),
        requestCourseStatus: LOADED,
        initialFormValues,
      };
    },
    [COURSE_REQUEST_ERROR]: (
      state: State,
      { payload: { errorMessage, errors } }: apiErrorAction
    ): State => ({
      ...state,
      requestCourseStatus: ERROR,
      requestCourseErrorMessage: errorMessage,
      requestCourseErrors: errors,
    }),
    [UPDATE_COURSE_REQUEST]: (state: State): State => ({
      ...state,
      updateCourseStatus: PENDING,
      updateCourseErrorMessage: "",
      updateCourseErrors: [],
    }),
    [UPDATE_COURSE_SUCCESS]: (state: State): State => ({
      ...state,
      updateCourseStatus: SUCCESS,
    }),
    [UPDATE_COURSE_ERROR]: (state: State): State => ({
      ...state,
      updateCourseStatus: ERROR,
      updateCourseErrorMessage: "",
      updateCourseErrors: [],
    }),
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default editCourse;
